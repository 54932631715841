.profile-container {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  height: 79vh;
}

.profile-navigation {
  width: 20%;
  height: 95%;
  border: 1.5px solid #f9912d;
  margin: auto;
  border-radius: 8px;
  padding: 20px;
}

.profile-navigation:hover {
  box-shadow: 0 2px 4px rgb(198, 198, 198);
}

.menu-list {
  list-style-type: none;
  width: 100%;
}

.menu-item {
  padding: 6px;
  border: 1px solid #f9912d;
  text-align: center;
  color: #f9912d;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.active-profile-menu {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.menu-item:hover {
  font-weight: 600;
}

.active-profile-menu:hover {
  font-weight: 500;
}

.profile-details {
  width: 75%;
  height: 95%;
  border: 1.5px solid #f9912d;
  border-radius: 8px;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-details:hover {
  box-shadow: 0 2px 4px rgb(198, 198, 198);
}

.profile-header {
  width: 55%;
  height: 97%;
}

.profile-title {
  font-weight: 500;
  color: #636363;
}

.profile-info {
  margin-top: 10px;
}

.profile-field {
  margin: 7px 0px;
  width: 95%;
}

.profile-label {
  color: #636363;
  font-size: 11px;
  font-weight: 500;
  width: 100%;
}

.profile-value {
  width: 100%;
  margin-top: 7px;
  color: #9b9c9c;
  padding: 7px 13px;
  border: 1.5px solid #636363;
  border-radius: 7px;
  font-size: 11px;
  font-weight: 500;
  outline: none;
}

.tagline-value {
  line-height: 15px;
  text-align: justify;
}

.tagline-text-area {
  height: 60px;
  resize: none;
}

.tagline-text-area::-webkit-scrollbar {
  display: none;
}

.borderless-profile-value {
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
}

.profile-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.profile-button {
  width: 45%;
  padding: 7px 12px;
  font-size: 11.5px;
  border: 1.5px solid #f9912d;
  border-radius: 6px;
  background-color: #fff;
  color: #f9912d;
  font-weight: 600;
  cursor: pointer;
}

.profile-button:hover {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
}

.profile-id-container {
  height: 80%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-id,
.profile-picture-label {
  font-size: 13px;
  font-weight: 600;
  color: #636363;
  text-align: center;
}

.profile-picture-label {
  margin-top: 20px;
}

.profile-picture {
  margin-top: 20px;
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
}

.profile-image {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}

.selected-img {
  border: 3px solid #f9912d;
}

.brand-header {
  height: 80%;
}

.brand-logo-container {
  height: 60%;
}

.brand-logo-text {
  text-align-last: left;
  width: 180px;
}

.brand-logo {
  margin-top: 20px;
  width: 180px;
  height: 160px;
  border-radius: 5px;
  border: 1.5px solid #636363;
  padding:10px;
  position: relative;
}

.brand-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  border-radius: 5px;
}

.brand-logo-upload-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand-logo-upload {
  width: 60%;
  padding: 5px 20px;
  background-color: #f37747;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.brand-logo-upload:hover {
  border: 1.5px solid #f37747;
  background-color: #fff;
  color: #f37747;
}

.upload-instruction {
  font-size: 11px;
  color: #636363;
  font-weight: 500;
  text-align: justify;
  width: 90%;
  margin: 15px 0px;
}

.upload-instruction span {
  color: #f37747;
}

.upload-support {
  color: #f37747;
  font-size: 11px;
  text-align: justify;
  width: 90%;
  font-weight: 500;
}

.brand-logo-delete-icon {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  color: #f37747;
}

.order-details {
  flex-direction: column;
  justify-content: start;
}

.order-history {
  width: 100%;
  display: flex;
  align-items: center;
}

.order-history-title {
  font-weight: 500;
  color: #636363;
  font-size: 17px;
  margin-right: 50px;
}

.order-search-bar input {
  padding: 6px 8px 6px 28px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #636363;
  outline: none;
  color: #f9912d;
  font-size: 12px;
}

.order-search-bar input::placeholder {
  color: #f9912d;
}

.order-search-icon {
  position: absolute;
  margin-top: 6px;
  margin-left: 8px;
  color: #f9912d;
}

.order-table {
  margin-top: 25px;
  width: 100%;
}

.order-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
}

.order-table th,
.order-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.order-table thead {
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  color: #fff;
  border-radius: 10px;
}

.order-table {
  overflow-y: auto;
}

.order-table::-webkit-scrollbar {
  display: none;
}

.order-table th {
  font-size: 12px;
  font-weight: 500;
}

.order-table .order-row:hover {
  background-color: #fff8f4;
}

.order-row td {
  font-size: 12px;
  padding: 10px 4px;
}

.mobile-profile-navigation {
  display: none;
}

@media screen and (max-width: 2570px) {
  .profile-container {
    width: 43%;
    height: 40vh;
    margin-top: 10%;
  }

  .profile-navigation {
    width: 23%;
  }

  .menu-item {
    padding: 8px 10px;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .profile-title {
    font-size: 25px;
  }

  .profile-info {
    margin-top: 10px;
  }

  .profile-field {
    margin: 20px 0px;
  }

  .profile-label {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .profile-value {
    padding: 7px 13px;
    font-size: 17.5px;
    margin-top: 10px;
  }

  .profile-button {
    width: 45%;
    padding: 10px 18px;
    font-size: 16px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 18px;
  }

  .profile-picture {
    margin-top: 40px;
    width: 60%;
  }

  .profile-image {
    width: 75px;
    height: 75px;
  }

  .brand-logo-text {
    font-size: 19px;
  }

  .brand-logo {
    width: 250px;
    height: 230px;
  }

  .brand-logo-upload {
    width: 70%;
    padding: 8px 20px;
  }

  .upload-instruction {
    font-size: 17px;
  }

  .upload-instruction span {
    color: #f37747;
  }

  .upload-support {
    font-size: 16px;
  }

  .brand-logo-delete-icon {
    font-size: 30px;
  }

  .order-history-title {
    font-size: 21px;
  }

  .order-search-bar input {
    width: 350px;
    font-size: 18px;
  }

  .order-table th {
    font-size: 17px;
  }

  .order-row td {
    font-size: 16px;
  }
}

@media screen and (max-width: 2000px) {
  .profile-container {
    width: 75%;
    height: 69vh;
    margin-top: 2%;
  }

  .profile-navigation ,.profile-details{
    height: 90%;
  }

  .profile-navigation {
    width: 23%;
  }

  .menu-item {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .profile-title {
    font-size: 20px;
  }

  .profile-info {
    margin-top: 10px;
  }

  .profile-field {
    margin: 15px 0px;
  }

  .profile-label {
    font-size: 14px;
    margin-bottom: 7px;
  }

  .profile-value {
    padding: 7px 13px;
    font-size: 14px;
    margin-top: 7px;
  }

  .profile-button {
    width: 45%;
    font-size: 14px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 15px;
  }

  .profile-picture {
    margin-top: 30px;
    width: 50%;
  }

  .profile-image {
    width: 55px;
    height: 55px;
  }


  .brand-logo-text {
    font-size: 15px;
  }

  .brand-logo {
    width: 200px;
    height: 180px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 13px;
  }

  .upload-support {
    font-size: 13px;
  }

  .brand-logo-delete-icon {
    font-size: 18px;
  }

  .order-history-title {
    font-size: 15px;
  }

  .order-search-bar input {
    width: 300px;
    font-size: 14px;
  }

  .order-table th {
    font-size: 14px;
  }

  .order-row td {
    font-size: 14px;
  }
}

@media screen and (max-width: 1400px) {
  .profile-navigation ,.profile-details{
    height: 90%;
  }

  .profile-container {
    width: 75%;
    height: 69vh;
    margin-top: 2%;
  }

  .menu-item {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 6px 10px;
  }

  .profile-title {
    font-size: 13px;
  }

  .profile-info {
    margin-top: 6px;
  }

  .profile-field {
    margin: 3px 0px;
  }

  .profile-label {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .profile-value {
    padding: 5px 13px;
    font-size: 10px;
    margin-top: 3px;
  }

  .profile-button {
    width: 45%;
    font-size: 11px;
    padding: 5px 10px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 12px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 50%;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }


  .brand-logo-text {
    font-size: 12px;
  }

  .brand-logo {
    width: 180px;
    height: 160px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 11px;
  }

  .upload-support {
    font-size: 11px;
  }

  .brand-logo-delete-icon {
    font-size: 15px;
  }

  .order-history-title {
    font-size: 13px;
  }

  .order-search-bar input {
    width: 300px;
    font-size: 12px;
  }

  .order-table th {
    font-size: 12px;
  }

  .order-row td {
    font-size: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .profile-container {
    width: 85%;
  }

  .menu-item {
    font-size: 11px;
    margin-bottom: 8px;
    padding: 6px 10px;
  }

  .profile-title {
    font-size: 14px;
  }

  .profile-info {
    margin-top: 5px;
  }

  .profile-field {
    margin: 5px 0px;
  }

  .profile-button {
    font-size: 11px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 11px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 45%;
  }

  .profile-image {
    width: 45px;
    height: 45px;
  }

  .brand-logo-text {
    font-size: 12px;
  }

  .brand-logo {
    width: 180px;
    height: 160px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 10px;
  }

  .upload-support {
    font-size: 10px;
  }

  .brand-logo-delete-icon {
    font-size: 14px;
  }

  .order-history-title {
    font-size: 13px;
  }

  .order-search-bar input {
    width: 300px;
    font-size: 12px;
  }

  .order-table th {
    font-size: 11px;
  }

  .order-row td {
    font-size: 11px;
  }
}

@media screen and (max-width: 950px) {
  .profile-container {
    width: 98%;
    height: 35vh;
    margin-top: 25%;
  }

  .profile-navigation {
    padding: 15px;
  }

  .menu-item {
    font-size: 14.5px;
    margin-bottom: 10px;
    padding: 6px 10px;
  }

  .profile-title {
    font-size: 19px;
  }

  .profile-info {
    margin-top: 6px;
  }

  .profile-field {
    margin: 10px 0px;
  }

  .profile-label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .profile-value {
    padding: 6px 13px;
    font-size: 14px;
    margin-top: 5px;
  }

  .profile-button {
    width: 45%;
    font-size: 16px;
    padding: 5px 10px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 15px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 60%;
  }

  .profile-image {
    width: 60px;
    height: 60px;
  }


  .brand-logo-text {
    font-size: 12px;
  }

  .brand-logo {
    width: 200px;
    height: 180px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 13px;
  }

  .upload-support {
    font-size: 13px;
  }

  .brand-logo-delete-icon {
    font-size: 18px;
  }

  .order-history-title {
    font-size: 16px;
  }

  .order-search-bar input {
    width: 300px;
    font-size: 14px;
  }

  .order-table th {
    font-size: 14px;
  }

  .order-row td {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .profile-container {
    width: 98%;
    height: 45vh;
    margin-top: 20%;
  }

  .profile-navigation {
    padding: 10px;
  }

  .menu-item {
    font-size: 13px;
    margin-bottom: 8px;
    padding: 6px 10px;
  }

  .profile-details {
    padding: 10px 20px;
  }

  .profile-title {
    font-size: 16px;
  }

  .profile-info {
    margin-top: 4px;
  }

  .profile-field {
    margin: 7px 0px;
  }

  .profile-label {
    font-size: 12px;
    margin-bottom: 3px;
  }

  .profile-value {
    padding: 6px 13px;
    font-size: 11px;
    margin-top: 3px;
  }

  .profile-button {
    width: 45%;
    font-size: 14px;
    padding: 5px 10px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 13px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 60%;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }


  .brand-logo-text {
    font-size: 13px;
  }

  .brand-logo {
    width: 190px;
    height: 170px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 12px;
  }

  .upload-support {
    font-size: 12px;
  }

  .brand-logo-delete-icon {
    font-size: 14px;
  }

  .order-history-title {
    font-size: 14px;
  }

  .order-search-bar input {
    width: 250px;
    font-size: 12px;
  }

  .order-table th {
    font-size: 12px;
  }

  .order-row td {
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .profile-container {
    width: 98%;
    height: 100vh;
    margin-top: 3%;
    flex-direction: column;
    align-items: start;
  }

  .profile-navigation {
    display: none;
  }

  .navigation-menu {
    position: absolute;
    width: 36%;
    padding: 5px 20px;
    padding-bottom: 20px;
    border: 1px solid #f9912d;
    border-radius: 0px 15px 0px 0px;
    background: linear-gradient(to bottom, #ffffff, #ffe8d1), #ffe8d1;
    z-index: 1;
  }

  .profile-navigation-menu-icon {
    font-size: 25px;
    color: #f9912d;
    z-index: 2;
    position: absolute;
  }

  .menu-list {
    list-style-type: none;
    width: 90%;
    margin: 30px auto 8px auto;
  }

  .menu-item {
    font-size: 13px;
    margin-bottom: 8px;
    padding: 6px 10px;
    background-color: #fff;
  }

  .profile-details {
    width: 95%;
    padding: 10px 20px;
    border: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
  }

  .profile-details:hover {
    box-shadow: 0 0px 0px rgb(198, 198, 198);
  }

  .profile-header {
    width: 90%;
    height: 68%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }

  .profile-title {
    font-size: 18px;
    text-align: center;
  }

  .profile-info {
    margin-top: 20px;
  }

  .profile-field {
    margin: 17px 0px;
    width: 100%;
  }

  .profile-label {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .profile-value {
    padding: 6px 13px;
    font-size: 14px;
    margin-top: 3px;
  }

  .profile-actions {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .profile-button {
    width: 45%;
    font-size: 14px;
    padding: 5px 10px;
  }

  .profile-id-container {
    height: 80%;
    width: 100%;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 13px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 100%;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }

  .brand-logo-text {
    font-size: 14px;
    text-align-last: center;
  }

  .brand-logo {
    width: 220px;
    height: 200px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 12px;
  }

  .upload-support {
    font-size: 12px;
  }

  .brand-logo-delete-icon {
    font-size: 14px;
  }

  .order-history-title {
    font-size: 14px;
  }

  .order-search-bar input {
    width: 250px;
    font-size: 12px;
  }

  .order-table th {
    font-size: 12px;
  }

  .order-row td {
    font-size: 12px;
  }

  .mobile-profile-navigation {
    display: block;
  }
}

@media screen and (max-width: 450px) {
  .profile-container {
    width: 98%;
    height: 75vh;
    margin-top: 3%;
    flex-direction: column;
    align-items: start;
  }

  .profile-navigation {
    display: none;
  }

  .navigation-menu {
    width: 45%;
  }

  .profile-navigation-menu-icon {
    font-size: 30px;
  }

  .menu-list {
    width: 95%;
  }

  .menu-item {
    font-size: 14px;
    margin-bottom: 12px;
    padding: 8px 10px;
  }

  .profile-details {
    width: 95%;
    padding: 10px 20px;
    border: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
  }

  .profile-details:hover {
    box-shadow: 0 0px 0px rgb(198, 198, 198);
  }

  .profile-header {
    width: 100%;
    height: 68%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }

  .profile-title {
    font-size: 20px;
  }

  .profile-info {
    margin-top: 20px;
  }

  .profile-field {
    margin: 17px 0px;
    width: 100%;
  }

  .profile-label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .profile-value {
    padding: 9px 13px;
    font-size: 14px;
    margin-top: 5px;
  }

  .profile-actions {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .profile-button {
    width: 45%;
    font-size: 14px;
    padding: 5px 10px;
  }

  .profile-id-container {
    height: 80%;
    width: 100%;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 14px;
  }

  .profile-picture {
    margin-top: 20px;
    width: 100%;
    justify-content: start;

  }

  .profile-image {
    width: 60px;
    height: 60px;
  }

  .brand-logo-text {
    font-size: 14px;
    text-align-last: center;
  }

  .brand-logo {
    width: 220px;
    height: 200px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 12px;
  }

  .upload-support {
    font-size: 12px;
  }

  .brand-logo-delete-icon {
    font-size: 14px;
  }

  .order-details {
    padding: 5px;
    width: 100%;
  }

  .order-history {
    flex-direction: column;
    width: 100%;
  }

  .order-history-title {
    font-size: 15px;
    margin: 10px 0 20px 0;
    width: 100%;
    text-align: center;
  }

  .order-search-bar {
    width: 95%;
    margin: 0 auto;
  }

  .order-search-icon {
    margin-top: 8px;
  }

  .order-search-bar input {
    width: 100%;
    font-size: 15px;
  }

  .order-table th {
    font-size: 12px;
  }

  .order-row td {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .profile-container {
    width: 98%;
    height: 100vh;
  }

  .profile-navigation {
    display: none;
  }

  .navigation-menu {
    width: 50%;
  }

  .profile-navigation-menu-icon {
    font-size: 30px;
  }

  .menu-list {
    width: 100%;
  }

  .menu-item {
    font-size: 12px;
    margin-bottom: 8px;
    padding: 6px 10px;
  }

  .profile-title {
    font-size: 15px;
  }

  .profile-field {
    margin: 10px 0px;
  }

  .profile-label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .profile-value {
    padding: 6px 13px;
    font-size: 12px;
  }

  .profile-button {
    font-size: 13px;
  }

  .profile-id,
  .profile-picture-label {
    font-size: 12px;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }

  .brand-logo-text {
    font-size: 12px;
  }

  .brand-logo {
    width: 200px;
    height: 180px;
  }

  .brand-logo-upload {
    width: 60%;
    padding: 6px 20px;
  }

  .upload-instruction {
    font-size: 12px;
  }

  .upload-support {
    font-size: 12px;
  }

  .brand-logo-delete-icon {
    font-size: 12px;
  }

  .order-history-title {
    font-size: 14px;
  }


  .order-search-icon {
    margin-top: 8px;
  }

  .order-search-bar input {
    width: 100%;
    font-size: 15px;
  }

  .order-table th {
    font-size: 12px;
  }

  .order-row td {
    font-size: 12px;
  }
}
