.payment-section {
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  background-color: rgb(117 115 115 / 50%);
  border-radius: 20px;
}

.payment-close-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  color: #f9912d;
  border: 1px solid #f9912d;
  background-color: #fff;
  border-radius: 50%;
  padding: 1px;
  font-size: 25px;
  width: 25px;
  margin-top: -25px;
  margin-right: -10px;
  right: 20.5%;
}

.payment-close-icon:hover{
background-color: #f9912d;
color: #fff;
}

.payment-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.payment-title {
  font-weight: 500;
  color: #636363;
  font-size: 21px;
  padding-bottom: 10px;
  border-bottom: 1px solid #636363;
}

.subscription-details {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.subscription-info {
  width: 55%;
}

.subscription-plan-title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  color: #5a5656;
}

.subscription-price {
  font-size: 13px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
  color: #5a5656;
}

.price-duration {
  font-weight: 400;
  font-size: 11px;
}

.referral-code-section {
  margin-top: 30px;
}

.referral-code-prompt {
  font-size: 11px;
  color: #1c1c1c;
  margin-bottom: 3px;
}

.referral-code-input {
  padding: 5px 8px;
  border-radius: 5px 0px 0px 5px;
  outline: none;
  border: 1px solid #5a5656;
  border-right: none;
  font-size: 11px;
  width: 220px;
}

.referral-code-redeem-button {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #f9912d;
  color: #f9912d;
  background-color: #fff;
  cursor: pointer;
  width: 70px;
}

.referral-discount-message {
  font-size: 10px;
  margin-top: 5px;
  color: black;
}

.error-redeem-message {
  color: red;
  width: 80%;
  text-align: justify;
}

.referral-discount-code {
  color: #049e1d;
  font-weight: 500;
}

.payment-order-summary {
  margin-top: 30px;
  padding: 5px 0px;
  width: 90%;
}

.payment-summary-item,
.payment-order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-summary-item {
  margin-bottom: 5px;
  padding: 5px;
}

.payment-order-total {
  margin-bottom: -15px;
}

.payment-summary-label,
.payment-summary-value,
.payment-order-label,
.payment-order-value {
  font-size: 10px;
  padding: 5px 0;
  font-weight: 600;
  color: #1c1c1c;
}

.payment-order-total {
  justify-content: space-around;
  border: 1.5px solid #f9912d;
  border-radius: 5px;
}

.payment-order-label,
.payment-order-value {
  color: #f9912d;
  padding: 6px;
}

.payment-newsletter-checkbox {
  font-size: 10px;
  color: #5a5656;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.payment-newsletter-checkbox span {
  margin-left: 3px;
}

.newsletter-checkbox-input {
  color: #f9912d;
  background-color: #f9912d;
}

.redeed-success-summary {
  margin-top: 15%;
  width: 82%;
  font-size: 12px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 3px #b7adad;
  background-color: #dfffe5;
}

.redeed-success-summary p {
  text-align: center;
  line-height: 20px;
  color: #049e1d;
  font-weight: 600;
}

.payment-method-section {
  position: relative;
  width: 45%;
}

.payment-method-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  border: 1.5px solid #c1bebe;
  border-radius: 20px;
}

.payment-background-img {
  width: 100%;
  height: 290px;
  border-radius: 20px;
}

.payment-method-content {
  position: relative;
  padding: 20px;
  border-radius: 8px;
}

.payment-method-title {
  color: #636363;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
}

.payment-method-options {
  width: 90%;
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #a8a7a7;
}

.payment-option {
  color: #c1bebe;
  font-size: 10px;
  padding: 4px;
}

.proceed-button {
  width: 90%;
  padding: 7px;
  text-align: center;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  margin-top: 50px;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
}

.consent-message {
  width: 90%;
  font-size: 8px;
  margin-top: 5px;
  color: #636363;
  font-weight: 500;
}

.privacy-policy {
  color: #f16f40;
  cursor: pointer;
}

@media screen and (max-width: 2570px) {
  .payment-section {
    margin-top: 10%;
    padding: 30px;
  }

  .payment-close-icon {
    font-size: 40px;
    width: 40px;
    right: 20%;
    margin-top: -35px;
  }

  .payment-title {
    font-size: 35px;
  }

  .subscription-details {
    padding: 20px;
  }

  .subscription-info {
    width: 55%;
  }

  .subscription-plan-title {
    font-size: 35px;
  }

  .subscription-price {
    font-size: 20px;
  }

  .price-duration {
    font-size: 16px;
  }

  .referral-code-prompt {
    font-size: 17px;
  }

  .referral-code-input {
    padding: 7px 10px;
    font-size: 17px;
    width: 65%;
  }

  .referral-code-redeem-button {
    padding: 7px 10px;
    font-size: 17px;
    width: 100px;
  }

  .referral-discount-message {
    font-size: 17px;
  }

  .referral-discount-code {
    color: #049e1d;
    font-weight: 500;
  }

  .redeed-success-summary p {
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
  }

  .payment-order-summary {
    margin-top: 30px;
    padding: 5px 0px;
    width: 90%;
  }

  .payment-summary-item {
    margin-bottom: 5px;
    padding: 5px;
  }

  .payment-order-total {
    margin-bottom: -15px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 23px;
  }

  .payment-background-img {
    height: 350px;
  }

  .redeed-success-summary {
    margin-top: 10%;
  }

  .proceed-button {
    padding: 10px;
    font-size: 21px;
  }

  .consent-message {
    font-size: 16px;
  }
}

@media screen and (max-width: 2000px) {
  .payment-section {
    margin-top: 2%;
    margin-bottom: 1%;
    padding: 20px;
  }

  .payment-close-icon {
    font-size: 30px;
    width: 30px;
    right: 20%;
    margin-top: -25px;
  }

  .payment-title {
    font-size: 25px;
  }

  .subscription-details {
    padding: 10px;
    padding-bottom: 0;
  }

  .subscription-plan-title {
    font-size: 25px;
  }

  .subscription-price {
    font-size: 14px;
  }

  .price-duration {
    font-size: 16px;
  }

  .referral-code-prompt {
    font-size: 17px;
  }

  .referral-code-input {
    padding: 5px 10px;
    font-size: 15px;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 15px;
    width: 90px;
  }

  .referral-discount-message {
    font-size: 14px;
  }

  .redeed-success-summary p {
    font-size: 13.5px;
    line-height: 20px;
    padding: 6px;
  }

  .payment-order-summary {
    margin-top: 30px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 14px;
  }

  .payment-background-img {
    height: 300px;
  }

  .redeed-success-summary {
    margin-top: 10%;
  }

  .proceed-button {
    padding: 5px;
    font-size: 15px;
  }

  .consent-message {
    font-size: 11.5px;
  }
}

@media screen and (max-width: 1400px) {
  .payment-section {
    width: 60%;
    margin-top: 1%;
  }

  .payment-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -25px;
    right: 20.5%;
  }

  .payment-container {
    padding: 20px;
  }

  .payment-title {
    font-size: 21px;
  }

  .subscription-plan-title {
    margin-top: 15px;
    font-size: 20px;
  }

  .subscription-price {
    font-size: 13px;
  }

  .price-duration {
    font-size: 11px;
  }

  .referral-code-prompt {
    font-size: 11px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 11px;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 11px;
  }

  .referral-discount-message {
    font-size: 10px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 10px;
  }

  .payment-newsletter-checkbox {
    font-size: 10px;
  }

  .redeed-success-summary p {
    font-size: 12px;
  }

  .payment-background-img {
    height: 290px;
  }

  .payment-method-title {
    font-size: 18px;
  }

  .payment-option {
    font-size: 10px;
  }

  .proceed-button {
    font-size: 12px;
  }

  .consent-message {
    font-size: 8px;
  }
}

@media screen and (max-width: 1100px) {
  .payment-section {
    width: 70%;
    margin-top: 1%;
    padding: 15px;
  }

  .payment-close-icon {
    font-size: 20px;
    width: 20px;
    margin-top: -18px;
    margin-left: -15px;
    right: 15.5%;
  }

  .payment-title {
    font-size: 19px;
  }

  .subscription-plan-title {
    margin-top: 15px;
    font-size: 18px;
  }

  .subscription-price {
    font-size: 11.5px;
  }

  .price-duration {
    font-size: 10px;
  }

  .referral-code-prompt {
    font-size: 10px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 10px;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 10px;
  }

  .referral-discount-message {
    font-size: 10px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 11px;
  }

  .redeed-success-summary p {
    font-size: 11px;
  }

  .payment-background-img {
    height: 270px;
  }

  .payment-method-title {
    font-size: 18px;
  }

  .payment-option {
    font-size: 10px;
  }

  .proceed-button {
    font-size: 11.5px;
  }

  .consent-message {
    font-size: 8px;
  }
}

@media screen and (max-width: 950px) {
  .payment-section {
    width: 90%;
    margin-top: 30%;
  }

  .payment-close-icon {
    font-size: 30px;
    width: 30px;
    margin-top: -25px;
    right: 5%;
  }

  .payment-title {
    font-size: 21px;
  }

  .subscription-plan-title {
    margin-top: 15px;
    font-size: 21px;
  }

  .subscription-price {
    font-size: 17px;
  }

  .price-duration {
    font-size: 12px;
  }

  .referral-code-prompt {
    font-size: 14px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 14px;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 14px;
  }

  .referral-discount-message {
    font-size: 14px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 14px;
  }

  .redeed-success-summary p {
    font-size: 13px;
  }

  .payment-background-img {
    height: 295px;
  }

  .payment-method-title {
    font-size: 18px;
  }

  .payment-option {
    font-size: 17px;
  }

  .proceed-button {
    font-size: 14px;
  }

  .consent-message {
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .payment-section {
    width: 95%;
    margin-top: 15%;
  }

  .payment-close-icon {
    font-size: 30px;
    width: 30px;
    margin-top: -25px;
    right: 2.5%;
  }

  .payment-title {
    font-size: 20px;
  }

  .subscription-plan-title {
    font-size: 18px;
  }

  .subscription-price {
    font-size: 16px;
  }

  .price-duration {
    font-size: 12px;
  }

  .referral-code-prompt {
    font-size: 13px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 13px;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 13px;
  }

  .referral-discount-message {
    font-size: 13px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 13.5px;
  }

  .redeed-success-summary p {
    font-size: 12px;
  }

  .payment-background-img {
    height: 295px;
  }

  .proceed-button {
    font-size: 13px;
  }

  .consent-message {
    font-size: 11px;
  }
}

@media screen and (max-width: 600px) {
  .payment-section {
    width: 95%;
    margin-top: 2%;
  }

  .payment-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -25px;
    right: 3%;
  }

  .payment-title {
    font-size: 14px;
  }

  .subscription-details {
    flex-direction: column;
    padding: 0;
  }

  .subscription-info {
    width: 100%;
  }

  .subscription-plan-title {
    font-size: 15px;
    text-align: center;
  }

  .payment-method-section {
    position: relative;
    width: 90%;
    margin: 20px auto;
  }

  .payment-method-background {
    width: 100%;
  }

  .subscription-price {
    font-size: 14px;
    text-align: center;
  }

  .price-duration {
    font-size: 12px;
  }

  .payment-order-summary {
    width: 90%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }

  .referral-code-prompt {
    font-size: 10px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 11px;
    width: 78%;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 11px;
    width: 22%;
  }

  .referral-discount-message {
    font-size: 11px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 13.5px;
  }

  .redeed-success-summary {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .redeed-success-summary p {
    font-size: 11px;
  }

  .payment-background-img {
    height: 295px;
  }

  .proceed-button {
    font-size: 13px;
    width: 100%;
  }

  .consent-message {
    width: 100%;
    font-size: 11px;
  }
}

@media screen and (max-width: 450px) {
  .payment-section {
    width: 100%;
    margin-top: 2%;
  }

  .payment-close-icon {
    font-size: 25px;
    width: 25px;
    margin-top: -20px;
    right: 2.5%;
  }

  .payment-title {
    font-size: 17px;
  }

  .subscription-plan-title {
    font-size: 17px;
  }

  .payment-method-section {
    width: 100%;
  }

  .subscription-price {
    font-size: 14px;
  }

  .price-duration {
    font-size: 12px;
  }

  .payment-order-summary {
    width: 95%;
  }

  .referral-code-prompt {
    font-size: 11.5px;
  }

  .referral-code-input {
    padding: 5px 8px;
    font-size: 12px;
    width: 78%;
  }

  .referral-code-redeem-button {
    padding: 5px 10px;
    font-size: 12px;
    width: 22%;
  }

  .referral-discount-message {
    font-size: 11px;
  }

  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 14px;
  }

  .redeed-success-summary {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 98%;
  }

  .redeed-success-summary p {
    font-size: 12px;
  }

  .payment-background-img {
    height: 295px;
  }

  .proceed-button {
    font-size: 13px;
    width: 100%;
    padding: 8px;
  }

  .consent-message {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {


  .payment-container {
    padding:20px 10px;
}


  .subscription-price {
    font-size: 13px;
  }

  .price-duration {
    font-size: 11px;
  }



  .payment-summary-label,
  .payment-summary-value,
  .payment-order-label,
  .payment-order-value {
    font-size: 13px;
  }

  .payment-background-img {
    height: 295px;
  }

  .proceed-button {
    font-size: 13px;
    width: 100%;
    padding: 8px;
  }

  .consent-message {
    font-size: 11px;
  }
}
