/* Container */
.organization-details-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: rgb(117 115 115 / 20%);
  border-radius: 10px;
  position: relative;
}

.organization-details-section {
  width: 100%;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #636363;
}

/* Title */
.organization-details-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.organization-details-group-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

/* Form styles */
.organization-details-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Group styles */
.organization-details-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Label */
.organization-details-label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 11px;
  color: #636363;
}

/* Input */
.organization-details-input {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  color: #636363;
  outline: none;
}

/* Placeholder */
.organization-details-input::placeholder {
  color: #aaa;
  font-style: italic;
}

/* GSTIN fields */
.organization-details-gstin {
  width: calc(50% - 10px);
  display: inline-block;
}

.organization-details-gstin + .organization-details-gstin {
  margin-left: 20px;
}

.organization-details-submit-btn {
  padding: 7px 10px;
  background: linear-gradient(to right, #eb4819 0%, #f78f5f 49%, #ff8d1b 100%);
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  width: 200px;
  margin: 0 auto;
}

.organization-file-upload-section {
  border: 1.5px solid #636363;
  border-radius: 10px;
  padding: 10px 0px;
  display: flex;
  height: 120px;
  position: relative;
}

.organization-file-upload-instruction {
  text-align: center;
}

.organization-uploaded-file {
  height: 100%;
  width: 50%;
  object-fit: contain;
  margin: 0px auto;
}
.organization-uploaded-delete-icon {
  position: absolute;
  cursor: pointer;
  color: #f37747;
  top: 5px;
  right: 5px;
}

@media screen and (max-width: 2570px) {
  /* Container */
  .organization-details-container {
    max-width: 900px;
    margin-top: 5%;
    padding: 30px;
  }

  .close-popup{
    width: 25px;
    font-size:25px;
  }


  /* Title */
  .organization-details-title {
    font-size: 24px;
  }


  /* Label */
  .organization-details-label {
    font-size: 16px;
  }

  /* Input */
  .organization-details-input {
    padding: 8px 10px;
    font-size: 19px;
  }


  .organization-details-submit-btn {
    padding: 9px 10px;
    font-size: 15px;
  }

  .organization-file-upload-section {
    height: 150px;
  }

  .organization-file-upload-instruction {
    text-align: center;
  }

}

@media screen and (max-width: 2000px) {
  /* Container */
  .organization-details-container {
    max-width: 700px;
    margin-top: 0%;
    padding: 20px;
  }

  .close-popup{
    width: 18px;
    font-size:18px;
  }


  /* Title */
  .organization-details-title {
    font-size: 16px;
  }


  /* Label */
  .organization-details-label {
    font-size: 14px;
  }

  /* Input */
  .organization-details-input {
    padding: 6px 10px;
    font-size: 14px;
  }


  .organization-details-submit-btn {
    padding: 7px 10px;
    font-size: 14px;
  }

  .organization-file-upload-section {
    height: 130px;
  }

}

@media screen and (max-width: 1400px) {

  .organization-details-container {
    max-width: 600px;
  }

  .close-popup{
    width: 16px;
    font-size:16px;
  }


  /* Title */
  .organization-details-title {
    font-size: 16px;
  }


  /* Label */
  .organization-details-label {
    font-size: 12px;
  }

  /* Input */
  .organization-details-input {
    font-size: 12px;
  }


  .organization-details-submit-btn {
    padding: 7px 10px;
    font-size: 12px;
  }

  .organization-file-upload-section {
    height: 120px;
  }

}

@media screen and (max-width: 950px) {

  .organization-details-container {
    max-width: 700px;
    padding: 30px;
    margin-top: 10%;
  }

  .close-popup{
    width: 25px;
    font-size:25px;
  }


  /* Title */
  .organization-details-title {
    font-size: 20px;
  }


  /* Label */
  .organization-details-label {
    font-size: 15px;
  }

  /* Input */
  .organization-details-input {
    font-size: 16px;
  }


  .organization-details-submit-btn {
    padding: 7px 10px;
    font-size: 16px;
  }

  .organization-file-upload-section {
    height: 150px;
  }

}

@media screen and (max-width: 850px) {

  .organization-details-container {
    max-width: 650px;
    padding: 20px;
    margin-top: 5%;
  }

  .close-popup{
    width: 18px;
    font-size:18px;
  }


  /* Title */
  .organization-details-title {
    font-size: 17px;
  }


  /* Label */
  .organization-details-label {
    font-size: 13px;
  }

  /* Input */
  .organization-details-input {
    font-size: 14px;
  }


  .organization-details-submit-btn {
    padding: 7px 10px;
    font-size: 15px;
  }

  .organization-file-upload-section {
    height: 130px;
  }

}

@media screen and (max-width: 800px) {

  .organization-details-container {
    margin-top: 0%;
  }

  .close-popup{
    width: 16px;
    font-size:16px;
  }
}

@media screen and (max-width: 600px) {

  .organization-details-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .organization-details-section {
    padding: 10px;
  }


  /* Title */
  .organization-details-title {
    font-size: 15px;
  }


  /* Label */
  .organization-details-label {
    font-size: 12px;
  }

  .organization-details-form {
    gap: 10px;
}

  /* Input */
  .organization-details-input {
    font-size: 11px;
  }

  .organization-details-group-container {
    gap: 10px;
}


  .organization-details-submit-btn {
    font-size: 14px;
  }

  .organization-file-upload-section {
    height: 130px;
  }

}

@media screen and (max-width: 450px) {

  .organization-details-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .organization-details-section {
    padding: 10px;
  }


  /* Title */
  .organization-details-title {
    font-size: 14px;
  }


  /* Label */
  .organization-details-label {
    font-size: 11px;
  }

  .organization-details-form {
    gap: 10px;
}

  /* Input */
  .organization-details-input {
    font-size: 11px;
  }

  .organization-details-group-container {
    gap: 10px;
    flex-direction: column;
}

  .organization-details-submit-btn {
    font-size: 13px;
  }

  .organization-file-upload-section {
    height: 130px;
  }

}